import React from 'react'
import "./style.css"

const Loader = () => {
  return (
    <div>
        <p className='main-loader'>Loading...</p>
    </div>
  )
}

export default Loader