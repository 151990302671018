// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposites-summary-box {
  background-color: #1c1c1c;
  border-radius: 16px;
  margin-bottom: 30px;
}
.deposites-summary-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #2d3530;
}
.deposites-summary-box-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}
.deposites-summary-box-header-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
.deposites-summary-box-chart{
  padding: 15px;
  margin-top: 10px;
}
.deposits-table-footer{
  background-color: #282828 !important; 
  border-radius: 0px 0px 16px 16px !important;
}
.deposits-table-footer-title{
  color: #FEFEFE;
font-size: 16px;
font-weight: 500;
width: 220px;
}
.deposits-table-footer-price{
  color: #FEFEFE;
font-size: 16px;
font-weight: 500;

}
.css-nx7hv4-MuiTableCell-root{
  border-bottom-width: 0px !important;

}
@media only screen and (max-width: 600px) {
  .deposites-summary-box-title {
    font-size: 16px;
  }
  .deposites-summary-box-header-text {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/deposits/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,wBAAwB;EACxB,0BAA0B;EAC1B,4BAA4B;AAC9B;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,oCAAoC;EACpC,2CAA2C;AAC7C;AACA;EACE,cAAc;AAChB,eAAe;AACf,gBAAgB;AAChB,YAAY;AACZ;AACA;EACE,cAAc;AAChB,eAAe;AACf,gBAAgB;;AAEhB;AACA;EACE,mCAAmC;;AAErC;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".deposites-summary-box {\n  background-color: #1c1c1c;\n  border-radius: 16px;\n  margin-bottom: 30px;\n}\n.deposites-summary-box-header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 20px 15px;\n  border-bottom-width: 1px;\n  border-bottom-style: solid;\n  border-bottom-color: #2d3530;\n}\n.deposites-summary-box-title {\n  color: #ffffff;\n  font-size: 20px;\n  font-weight: 600;\n}\n.deposites-summary-box-header-text {\n  color: #ffffff;\n  font-size: 14px;\n  font-weight: 400;\n}\n.deposites-summary-box-chart{\n  padding: 15px;\n  margin-top: 10px;\n}\n.deposits-table-footer{\n  background-color: #282828 !important; \n  border-radius: 0px 0px 16px 16px !important;\n}\n.deposits-table-footer-title{\n  color: #FEFEFE;\nfont-size: 16px;\nfont-weight: 500;\nwidth: 220px;\n}\n.deposits-table-footer-price{\n  color: #FEFEFE;\nfont-size: 16px;\nfont-weight: 500;\n\n}\n.css-nx7hv4-MuiTableCell-root{\n  border-bottom-width: 0px !important;\n\n}\n@media only screen and (max-width: 600px) {\n  .deposites-summary-box-title {\n    font-size: 16px;\n  }\n  .deposites-summary-box-header-text {\n    font-size: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
