// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0px;
  margin: 0px;
  font-family: "Readex Pro";
}
body {
  background-color: #080808;
}
input {
  border: none;
  background: none;
  outline: none;
}

.cursor-pointer{
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","sourcesContent":["* {\n  padding: 0px;\n  margin: 0px;\n  font-family: \"Readex Pro\";\n}\nbody {\n  background-color: #080808;\n}\ninput {\n  border: none;\n  background: none;\n  outline: none;\n}\n\n.cursor-pointer{\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
