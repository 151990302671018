// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-wallet-table {
  background-color: #1c1c1c;
  border-radius: 16px;
}

.table-loader {
  color: white;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/user-wallets/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".user-wallet-table {\n  background-color: #1c1c1c;\n  border-radius: 16px;\n}\n\n.table-loader {\n  color: white;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
