import Logo from "./logo.png";
import Card1 from "./card-1.png";
import Card2 from "./card-2.png";
import Card3 from "./card-3.png";
import Card4 from "./card-4.png";
import Graph from "./graphs.png";
import LoginBg from "./login-image.png"
import LoginSmallLogo from "./login-small-img.png"
import passwordUpdatedImg from "./WelcomebackFabiana.png"

export { Logo, Card1, Card2, Card3, Card4, Graph, LoginBg,  LoginSmallLogo,passwordUpdatedImg };

